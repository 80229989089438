import store from '@/store'

export function shouldShowOrHideFiling(filter, getters, jurisdiction) {
  const formationActiveOrInCart = !!getters.formACompanyFiling ||
    store.getters['checkout/formationProductInCart']
  const boiActiveOrInCart = !!getters.activeBoiFiling || !!getters.boiFilingInCart

  return (
    (filter.includes('form a company') && formationActiveOrInCart)
    || (filter.includes('beneficial ownership information report') && boiActiveOrInCart)
    || ((filter.includes('operating agreement') || filter.includes('corporate bylaws')) &&
      getters.purchasedLegalDocs)
    || getters.jurisdictionFilings.some(filing => filter.includes(filing.product.filing_name))
    || filter.some(category => {
      return store.getters['companies/activeOrderItemsByCategoryAndJurisdiction'](category, jurisdiction).length > 0
    })
  )
}
