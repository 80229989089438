import Vue from 'vue'
import store from '@/store'
import packageJson from '@/../package.json'
import CtFileDrop from '@/components/shared/CtFileDrop'
import CtAutoSave from '@/components/shared/CtAutoSave'
import ContactModal from '@/components/ContactModal'
import { helpers } from '@core/data-map-frontend/src/helpers'
import packageVersions from '@core/data-map-frontend/src/store/modules/packageVersions.store'
import packageVersions0 from '@core/data-map-frontend/src/store/modules/packageVersions.store'
import packageVersions1 from 'data-map-long-term-support/src/store/modules/packageVersions.store'
import packageVersions2 from 'data-map-stable/src/store/modules/packageVersions.store'
import packageVersions3 from 'data-map-feature-A/src/store/modules/packageVersions.store'
import packageVersions4 from 'data-map-feature-B/src/store/modules/packageVersions.store'
import packageVersions5 from 'data-map-feature-C/src/store/modules/packageVersions.store'
import dataMap from '@core/data-map-frontend/src/store/modules/dataMap.store'
import dataMap0 from '@core/data-map-frontend/src/store/modules/dataMap.store'
import dataMap1 from 'data-map-long-term-support/src/store/modules/dataMap.store'
import dataMap2 from 'data-map-stable/src/store/modules/dataMap.store'
import dataMap3 from 'data-map-feature-A/src/store/modules/dataMap.store'
import dataMap4 from 'data-map-feature-B/src/store/modules/dataMap.store'
import dataMap5 from 'data-map-feature-C/src/store/modules/dataMap.store'

// Let Data Map know if you make breaking changes for any of the following
// Or you could do a search in here and see if it would break anything if you really wanted to :)
// /node_modules/@core/data-map-frontend

// Data Map uses these components:
// <ct-file-drop />
// <ct-auto-save />
// <contact-modal />

// Data Map uses these store interactions:
// account/peopleWithAllRequiredInfo
// checkout/addToCart
// checkout/raServiceActiveOrInCart
// jurisdictions/findByName

export function dataMapPackageInit() {
  const newestVersion =
    helpers.sortPackageVersions(availablePackageVersions.filter(v => v !== 'Linked')).at(-1)
  const newestPackageModule =
    packageVersionsModules[availablePackageVersions.indexOf(newestVersion)]

  store.registerModule('packageVersions', packageVersions)
  store.registerModule('packageVersionsNewest', newestPackageModule)
  store.registerModule('dataMap', dataMap)

  const components = { CtFileDrop, CtAutoSave, ContactModal }
  Object.entries(components).forEach(([name, component]) => {
    Vue.component(name, component)
  })
}

export const packageVersionNames = [
  '@core/data-map-frontend', 'data-map-long-term-support', 'data-map-stable',
  'data-map-feature-A', 'data-map-feature-B', 'data-map-feature-C',
]

const availablePackageVersions =
  packageVersionNames.map(name => packageJson.dependencies[name].split('@').pop())
if (!require.resolve('@core/data-map-frontend/package.json').includes('node_modules'))
  availablePackageVersions[0] = 'Linked'
export { availablePackageVersions }

export const packageVersionsModules = [
  packageVersions0, packageVersions1, packageVersions2,
  packageVersions3, packageVersions4, packageVersions5,
]
export const dataMapModules = [dataMap0, dataMap1, dataMap2, dataMap3, dataMap4, dataMap5]
