var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ct-file-drop" },
    [
      _vm.loaded
        ? _c(
            "div",
            {
              staticClass: "dropzone-container",
              class: {
                dragging: _vm.dragging,
                filesUploading: _vm.filesUploading,
              },
              on: {
                dragover: function ($event) {
                  $event.preventDefault()
                  return _vm.dragover.apply(null, arguments)
                },
                dragleave: _vm.dragleave,
                drop: function ($event) {
                  $event.preventDefault()
                  return _vm.uploadFiles.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                ref: "file-input",
                attrs: {
                  id: "file-input",
                  type: "file",
                  name: "file-input",
                  accept: _vm.acceptableFileTypes.join(","),
                },
                on: {
                  change: function ($event) {
                    $event.preventDefault()
                    return _vm.uploadFiles.apply(null, arguments)
                  },
                },
              }),
              _c(
                "label",
                { staticClass: "file-label", attrs: { for: "file-input" } },
                [
                  _c(
                    "div",
                    { staticClass: "label-content" },
                    [
                      _vm.files && _vm.files.length <= 0
                        ? [
                            _vm.dragging
                              ? _c("div", [
                                  _vm._v("Release to drop files here."),
                                ])
                              : _vm._e(),
                            !_vm.dragging && !_vm.filesUploading
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center flex-column",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "file-icon-container" },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "file-text",
                                            width: "74",
                                            height: "74",
                                            "stroke-width": 1.2,
                                          },
                                        }),
                                        _c("div", { staticClass: "ct-plus" }, [
                                          _vm._v(
                                            "\n                +\n              "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c("span", { staticClass: "ct-link" }, [
                                      _vm._v("Select Document to upload"),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: !["xs", "sm"].includes(
                                              _vm.$mq
                                            ),
                                            expression:
                                              "!['xs', 'sm'].includes($mq)",
                                          },
                                        ],
                                      },
                                      [_vm._v("Or drag and drop Document here")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.filesUploading
                              ? _c(
                                  "div",
                                  { staticClass: "uploading" },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        type: "upload-cloud",
                                        height: "80",
                                        width: "80",
                                        stroke: "#000864",
                                      },
                                    }),
                                    _c("div", [_vm._v("Uploading file...")]),
                                    _c("ct-fake-loading-bar", {
                                      staticClass: "loading-bar",
                                      attrs: {
                                        "seconds-estimate": _vm.secondsEstimate,
                                        "actually-complete":
                                          !_vm.filesUploading &&
                                          _vm.filesUploaded,
                                      },
                                      on: {
                                        "progress-bar-complete": function (
                                          $event
                                        ) {
                                          _vm.progressBarComplete = true
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _c("ct-centered-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }